// Config
support-for-ie = false
vendor-prefixes = webkit moz ms official

// Colors
color-default = rgb(56, 56, 56)
color-featured = #00c853;
color-grey = #999
color-border = #ddd
color-link = #258fb8
color-background = #eee
color-sidebar-text = #777
color-footer-background = #262a30
color-mobile-nav-background = #191919

color-twitter = #00aced
color-facebook = #3b5998
color-pinterest = #cb2027
color-google = #dd4b39

// Fonts
font-sans = "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif
font-serif = Georgia, "Times New Roman", serif
font-mono = "Source Code Pro", Consolas, Monaco, Menlo, Consolas, monospace
font-icon = FontAwesome
//font-icon-path = "fonts/fontawesome-webfont"
//font-icon-version = "4.0.3"
font-size = 18px
line-height = 1.6em
line-height-title = 1.1em

block-margin = 50px
article-padding = 20px


navbar-height = 80px;


.color-featured {
  color: color-featured;
}

.bg-featured {
  background: color-featured;
}


$block-caption
  text-decoration: none
  text-transform: uppercase
  letter-spacing: 2px
  color: color-grey
  margin-bottom: 1em
  margin-left: 5px
  line-height: 1em
  text-shadow: 0 1px #fff
  font-weight: bold


$block
  background: #fff
  box-shadow: 1px 2px 3px #ddd
  border: 1px solid color-border
  border-radius: 3px


$base-style
  h1
    font-size: 2em
  h2
    font-size: 1.5em
  h3
    font-size: 1.3em
  h4
    font-size: 1.2em
  h5
    font-size: 1em
  h6
    font-size: 1em
    color: color-grey
  hr
    border: 1px dashed color-border
  strong
    font-weight: bold
  em, cite
    font-style: italic
  sup, sub
    font-size: 0.75em
    line-height: 0
    position: relative
    vertical-align: baseline
  sup
    top: -0.5em
  sub
    bottom: -0.2em
  small
    font-size: 0.85em
  acronym, abbr
    border-bottom: 1px dotted
  ul, ol, dl
    margin: 0 20px
    line-height: line-height
  ul, ol
    ul, ol
      margin-top: 0
      margin-bottom: 0
  ul li
    list-style: disc
  ol li
    list-style: decimal
  dt
    font-weight: bold
