@import "nib"
@import "_variables"
@import "_util/mixin"

//global-reset()

html {
  font-size: font-size
  font-family: font-sans;
}

body
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  color: color-default;

sub {
  bottom: 0;
}

strong {
  font-weight: 600;
}

#main-container {
  margin-top: 20px;
  flex: 1 0 auto;
}

@import "_partial/article";
@import "_partial/header";
@import "_partial/highlight";
@import "_partial/index";
